import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';

@Injectable()
export class AppTitleService {
  currentTitle = new BehaviorSubject<string>('');
  currentPortal = new BehaviorSubject<MainRoutes>(MainRoutes.NoAccess);

  constructor(
    private title: Title,
    private router: Router,
  ) {
    this.listenToRoute();
  }

  private getPortalPath(url: string): string {
    return _.get(url.split('/'), '[1]', '');
  }

  private listenToRoute(): void {
    this.router.events
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        this.setTitle(this.getPortalPath(this.router.url));
      });
  }

  private setTitle(path: string): void {
    let titleToSet: string;

    switch (path) {
      case MainRoutes.Designer:
        titleToSet = 'Portal Projektanta';
        break;
      case MainRoutes.Przp:
        titleToSet = 'Portal PRZP';
        break;
      case MainRoutes.GrantingRights:
        titleToSet = 'Portal do nadawania uprawnień';
        break;
      case MainRoutes.NetworkServiceOrder:
        titleToSet = 'Portal do zamawiania usług sieciowych';
        break;
      case MainRoutes.EcoPortal:
        titleToSet =
          'EkoPortal - wykaz danych o dokumentach zawierających informacje o środowisku i jego ochronie';
        break;
      case MainRoutes.RoadExitPortal:
        titleToSet =
          'Wniosek o zezwolenie na lokalizację zjazdu / przebudowę istniejącego zjazdu';
        break;
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse:
        titleToSet =
          'Wniosek o wydanie decyzji zezwalającej na wyłączenie gruntów rolnych z produkcji rolniczej';
        break;
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse:
        titleToSet =
          'Zgłoszenie rozpoczęcia innego niż rolnicze użytkowania gruntów tj. faktycznego wyłączenia z produkcji rolniczej';
        break;
      case MainRoutes.LaneOccupancyPortalInfrastructureDevices:
        titleToSet =
          'Wniosek o zezwolenie na zajęcie pasa drogowego w związku z umieszczeniem urządzeń infrastruktury w pasie drogowym';
        break;
      case MainRoutes.LaneOccupancyPortalBuildingObjects:
        titleToSet =
          'Wniosek o zezwolenie na zajęcie pasa drogowego w związku umieszczeniem obiektów budowlanych niezwiązanych z potrzebami zarządzania drogami lub potrzebami ruchu drogowego oraz reklam';
        break;
      case MainRoutes.LaneOccupancyPortalBuildingWorks:
        titleToSet =
          'Wniosek o zezwolenie na zajęcie pasa drogowego w związku z prowadzeniem robót budowlanych w pasie drogowym';
        break;
      case MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest:
        titleToSet = 'Zgłoszenie zmian danych w ewidencji gruntów i budynków';
        break;
      case MainRoutes.SoilClassificationOfLandRequest:
        titleToSet =
          'Wniosek o przeprowadzenie gleboznawczej klasyfikacji gruntów"';
        break;
      case MainRoutes.PresentationCommentsLandBuildingRegistryProject:
        titleToSet =
          'Wniosek do prezentacji/zgłaszania uwag do wyłożonego projektu ewidencji gruntów i budynków';
        break;
      case MainRoutes.AccessToInformationOnEnvironmentAndItsProtection:
        titleToSet =
          'Wniosek o udostępnienie informacji o środowisku i jego ochronie';
        break;
      case MainRoutes.BuildingPermit:
        titleToSet = 'Wniosek o pozwolenie na budowę';
        break;
      case MainRoutes.DemolitionPermit:
        titleToSet = 'Wniosek o pozwolenie na rozbiórkę';
        break;
      case MainRoutes.BuildingOrPartUseChange:
        titleToSet =
          'Zgłoszenie zmiany sposobu użytkowania obiektu budowlanego lub jego części';
        break;
      case MainRoutes.TemporaryStructureBuildingPermit:
        titleToSet =
          'Wniosek o wydanie pozwolenia na budowę tymczasowego obiektu budowlanego';
        break;
      case MainRoutes.BuildingPermitModificationDecision:
        titleToSet = 'Wniosek o wydanie decyzji o zmianie pozwolenia na budowę';
        break;
      case MainRoutes.NotObjectedNotificationRightsObligationsTransfer:
        titleToSet =
          'Wniosek o przeniesienie praw i obowiązków wynikających ze zgłoszenia, wobec którego organ nie wniósł sprzeciwu';
        break;
      case MainRoutes.PlotLandDevArchConstrProjSeparateDecision:
        titleToSet =
          'Wniosek o wydanie odrębnej decyzji o zatwierdzeniu projektu zagospodarowania działki lub terenu lub projektu architektoniczno-budowlanego';
        break;
      case MainRoutes.AdjacentPropertyNeedEnterDecision:
        titleToSet =
          'Wniosek o wydanie decyzji o niezbędności wejścia do sąsiedniego budynku, lokalu lub na teren sąsiedniej nieruchomości';
        break;
      case MainRoutes.SingleFamilyBuilding70ConstructionConversion:
        titleToSet =
          'Zgłoszenie budowy lub przebudowy budynku mieszkalnego jednorodzinnego do 70m²';
        break;
      case MainRoutes.SingleFamilyBuildingConstructionConversion:
        titleToSet =
          'Zgłoszenie budowy lub przebudowy budynku mieszkalnego jednorodzinnego';
        break;
      case MainRoutes.GeodeticAndCartographicServiceDataRequest:
        titleToSet =
          'Wniosek o udostępnienie danych dla Służby Geodezyjnej i Kartograficznej';
        break;
      case MainRoutes.EnvironmentalProtectionPortalFishingCardRequest:
        titleToSet = 'Wniosek o wydanie karty wędkarskiej';
        break;
      case MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest:
        titleToSet = 'Wniosek o nadanie nr jednostki pływającej';
        break;
      case MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest:
        titleToSet = 'Wniosek o wydanie zaświadczenia o objęciu działki UPUL';
        break;
      case MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest:
        titleToSet = 'Wniosek o zezwolenie na wycinkę drzew i krzewów';
        break;
      case MainRoutes.ConstructionPortalConstructionNotificationRequest:
        titleToSet = 'Zgłoszenie zamiaru budowy';
        break;
      case MainRoutes.BuildingPermitTransfer:
        titleToSet = 'Wniosek o przeniesienie decyzji o pozwoleniu na budowę';
        break;
      case MainRoutes.ConstructionPortalDemolitionNotificationRequest:
        titleToSet = 'Zgłoszenie rozbiórki';
        break;
      case MainRoutes.ConstructionPortalPremiseIndependenceRequest:
        titleToSet = 'Wniosek o wydanie zaświadczenia o samodzielności lokalu';
        break;
      case MainRoutes.ConstructionPortalConstructionLogRequest:
        titleToSet = 'Rejestracja dziennika budowy';
        break;
      case MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan:
        titleToSet =
          'Wypis i/lub wyrys z planu zagospodarowania przestrzennego';
        break;
      case MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan:
        titleToSet =
          'Wniosek o zaświadczenie z planu zagospodarowania przestrzennego';
        break;
      case MainRoutes.PlanningDepartmentExtractFromStudy:
        titleToSet =
          'Wniosek o wypis i/lub wyrys ze studium uwarunkowań i kierunków zagospodarowania przestrzennego';
        break;
      case MainRoutes.AddressNumberRequestPortal:
        titleToSet = 'Wniosek o nadanie numeru adresowego';
        break;
      case MainRoutes.SurveyorPortal:
        titleToSet = 'mGeodeta';
        break;
      case MainRoutes.AppraiserPortal:
        titleToSet = 'mRzeczoznawca';
        break;
      case MainRoutes.PropertyLease:
        titleToSet =
          'Wniosek o wydzierżawienie nieruchomości komunalnej lub Skarbu Państwa';
        break;
      case MainRoutes.SupplementaryParcelPurchase:
        titleToSet = 'Wniosek o zakup działki uzupełniającej';
        break;
      case MainRoutes.OwnershipConversion:
        titleToSet =
          'Wniosek o przekształcenie / wykupienie prawa użytkowania wieczystego w prawo własności';
        break;
      default:
        titleToSet = 'Portal';
    }

    this.currentPortal.next(path as MainRoutes);
    this.title.setTitle(titleToSet);
    this.currentTitle.next(titleToSet);
  }
}
