import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedDocToSignResponse,
  AddedDocToSignResponseFromApi,
  EnablementDto,
  PetentData,
  PzService,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { DateHelperUtil } from '../../utils/date-helper/date-helper.util';
import { AppTitleService } from '../app-title/app-title.service';
import { AuthorizedPersonToRegistryView } from '../authorized-person-to-registry-view/authorized-person-to-registry-view.model';
import { RangeType } from '../parcel/parcel.model';
import { handleApiError } from '../services.utils';
import { WebPortalService } from '../web-portal/web-portal.service';
import {
  NewRequestApiModel,
  NewRequestModel,
  PrzpZamowienieZakresRequestDto,
} from './new-request.model';

@Injectable()
export class NewRequestService {
  constructor(
    private httpClient: HttpClient,
    private appTitleService: AppTitleService,
    private webPortalService: WebPortalService,
    private pzService: PzService,
  ) {}

  previewNewRequest(formValue: NewRequestModel): Observable<Blob> {
    return this.httpClient.post(
      '/api/interesant/przp/wniosek/podglad',
      this.newRequestFormModelToApiModel(formValue),
      { responseType: 'blob' },
    );
  }

  private getRangeForApi(
    formValue: NewRequestModel,
  ): PrzpZamowienieZakresRequestDto {
    const parcelIds = (formValue.parcels || []).map((parcel) => parcel.uuid);
    const districtIds = (formValue.districts || []).map(
      (district) => district.districtId,
    );

    return new PrzpZamowienieZakresRequestDto(
      formValue.rangeType === RangeType.Parcels ? parcelIds : null,
      formValue.rangeType === RangeType.Districts ? districtIds : null,
      formValue.rangeType === RangeType.Wkt ? formValue.wkt : null,
    );
  }

  newRequestFormModelToApiModel = (
    formValue: NewRequestModel,
    petent?: PetentData,
  ): NewRequestApiModel => ({
    IdPetenta: formValue.applicantId || petent?.id,
    CelId: formValue.purpose && formValue.purpose.id,
    Par1: formValue.par1,
    Par2: formValue.par2,
    Uwagi: formValue.remarks,
    UpowaznieniInfo:
      formValue.authorizedPersonsToRegistryView &&
      formValue.authorizedPersonsToRegistryView.length
        ? formValue.authorizedPersonsToRegistryView.map((person) =>
            AuthorizedPersonToRegistryView.fromAppToApi(person),
          )
        : null,
    CzasDostepu: {
      CzasDostepuOd: formValue?.period?.from
        ? DateHelperUtil.ngbDateToApiDateTime(formValue.period.from)
        : undefined,
      CzasDostepuDo: formValue?.period?.to
        ? DateHelperUtil.ngbDateToApiDateTime(formValue.period.to)
        : undefined,
    },
    RejPublId: parseInt(formValue.publicRegister, 10),
    ZakresDaneOs: formValue.personalData,
    Zakres: this.getRangeForApi(formValue),
    Pelnomocnictwo: EnablementDto.fromAppToApi(formValue.enablement),
  });

  downloadRequest(pUuid: string): Observable<Blob> {
    return this.appTitleService.currentPortal.pipe(
      filter((portalRoute) => !!portalRoute),
      switchMap((portalRoute) =>
        this.webPortalService.getWebPortalDataByCurrentRoute(portalRoute),
      ),
      switchMap((webPortal) => {
        const requestBody = {
          FileUuids: pUuid,
          PortalId: webPortal.portalId,
        };

        return this.httpClient
          .post('/api/interesant/przp/dokument/download', requestBody, {
            responseType: 'blob',
          })
          .pipe(catchError(handleApiError));
      }),
    );
  }

  getResponseStatusMessage(error: string): string {
    return _.get(error, 'error.ResponseStatus.Message', '');
  }

  valideDtoAndAddToSign(
    formValue: NewRequestModel,
  ): Observable<AddedDocToSignResponse> {
    return this.pzService.getPetentData().pipe(
      switchMap((petent) =>
        this.httpClient.post<AddedDocToSignResponseFromApi>(
          '/api/interesant/przp/wniosek/addToSign',
          this.newRequestFormModelToApiModel(formValue, petent),
        ),
      ),
      map((addedDocToSignResponseFromApi) =>
        AddedDocToSignResponse.fromApiToApp(addedDocToSignResponseFromApi),
      ),
    );
  }
}
