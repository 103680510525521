<h5 tabindex="0" aria-required="true">
  {{
  (legalPersonOnly
  ? 'SELECTED_LAW_PERSONS.HEADER_LEGAL_PERSON'
  : 'SELECTED_LAW_PERSONS.HEADER'
  ) | translate
  }}
</h5>
<div
  class="table-responsive table-bordered table-hover map-object-table text-center"
  >
  <table class="table table-sm">
    <thead class="thead-color-from-db">
      <tr>
        <th scope="col">#</th>
        <th scope="col">
          <span>{{ 'SELECTED_LAW_PERSONS.NAME' | translate }}</span>
        </th>
        @if (shouldShowPersonTypeColumn()) {
          <th>
            <span>{{ 'SELECTED_LAW_PERSONS.PERSON_TYPE' | translate }}</span>
          </th>
        }
        <th scope="col">
          <span>{{ 'SELECTED_LAW_PERSONS.ADDRESS' | translate }}</span>
        </th>
        <th scope="col">
          <span>{{
            (legalPersonOnly
            ? 'SELECTED_LAW_PERSONS.REGON'
            : 'SELECTED_LAW_PERSONS.PESEL_OR_REGON'
            ) | translate
          }}</span>
        </th>
        <th scope="col">
          <span> {{ 'SELECTED_LAW_PERSONS.REMOVE' | translate }}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      @for (staticPerson of staticSelectedTableValue; track staticPerson; let i = $index) {
        <tr
          class="text-muted"
          [ngClass]="{ required: isPersonTypeRequired(staticPerson) }"
          [attr.aria-required]="isPersonTypeRequired(staticPerson)"
          >
          <td>{{ i + 1 }}</td>
          <td scope="row" class="text-uppercase">
            {{
            getLawPersonName(
            staticPerson,
            'LAW_PERSON_LIST.PERMISSION_NUMBER_LABEL' | translate
            )
            }}
          </td>
          @if (shouldShowPersonTypeColumn()) {
            <td scope="row">
              {{ getPersonTypeName(staticPerson) }}
            </td>
          }
          <td>{{ staticPerson.address }}</td>
          <td>{{ getLawPersonRegonOrPesel(staticPerson) }}</td>
          <td></td>
        </tr>
      }
      @for (lawPerson of lawPersons; track lawPerson; let i = $index) {
        <tr
          [ngClass]="{ required: isPersonTypeRequired(lawPerson) }"
          [attr.aria-required]="isPersonTypeRequired(lawPerson)"
          >
          <td>{{ i + (1 + staticSelectedTableValue.length) }}</td>
          <td scope="row" class="text-uppercase">
            {{
            getLawPersonName(
            lawPerson,
            'LAW_PERSON_LIST.PERMISSION_NUMBER_LABEL' | translate
            )
            }}
          </td>
          @if (shouldShowPersonTypeColumn()) {
            <td scope="row">
              @if (
                lawPerson.availablePersonTypes || defaultAvailablePersonTypes) {
                <select
                  [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
                  name="personTypeFormControl"
                  [(ngModel)]="lawPerson.personType"
                  [compareWith]="comparePerson"
              (ngModelChange)="
                lawPersonService.selectedPersonValueChange.next($event)
              "
                  class="form-select form-select-sm required"
                  aria-required="true"
                  id="person-type"
                  >
                  <option [value]="undefined" hidden selected>
                    {{
                    'SELECTED_LAW_PERSONS.PERSON_TYPE_SELECT_PLACEHOLDER'
                    | translate
                    }}
                  </option>
                  @for (
                    opt of lawPerson.availablePersonTypes &&
                    lawPerson.availablePersonTypes.length
                    ? lawPerson.availablePersonTypes
                    : defaultAvailablePersonTypes
                    ; track
                    opt) {
                    <option
                      [ngClass]="{ required: opt.isRequired }"
                      [attr.aria-required]="opt.isRequired"
                      [ngValue]="opt"
                      >
                      {{ opt.name }}
                    </option>
                  }
                </select>
              } @else {
                {{ getPersonTypeName(lawPerson) }}
              }
            </td>
          }
          <td>{{ getAddress(lawPerson) }}</td>
          <td>{{ getLawPersonRegonOrPesel(lawPerson) }}</td>
          <td>
            <button
              type="button"
              class="btn btn-danger btn-sm gk-map-icon-remove"
              [attr.aria-label]="'GK.MAP.REMOVE' | translate"
              [disabled]="!lawPerson.canBeDeleted"
              (click)="removeLawPerson(lawPerson, $event)"
            ></button>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
