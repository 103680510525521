import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  NaturalPersonControlName,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ShippingFormService } from '../../../../services/shipping-form/shipping-form.service';
import {
  ATIOEAIPNewRequestControlName,
  ATIOEAIPNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class ATIOEAIPNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): FormGroup<ATIOEAIPNewRequestFormGroup> {
    return this.formBuilder.group({
      [ATIOEAIPNewRequestControlName.LawPerson]: this.getLawPersonFormGroup(),
      [ATIOEAIPNewRequestControlName.DecisionForm]: [
        DecisionFormType.NonElectronicForm,
        [Validators.required],
      ],
      [ATIOEAIPNewRequestControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
      [ATIOEAIPNewRequestControlName.DetailedScope]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [ATIOEAIPNewRequestControlName.AdditionalIndications]: [
        getDefaultControlValue(),
      ],
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.TypeId]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LegalPersonControlName.Name]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.Address]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
      }),
    });
  }
}
