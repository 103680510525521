import { EventEmitter } from '@angular/core';
import { ButtonThemeColor } from '@progress/kendo-angular-buttons/common/models/theme-color';
import { DisplayMode } from '@progress/kendo-angular-toolbar';
import { SVGIcon } from '@progress/kendo-svg-icons';
import {
  iwIconColorNarysujZakres2,
  iwIconColorSz,
  iwIconCross,
  iwIconInfo,
  iwIconMinus,
  iwIconPlus,
  iwIconWrysujZakres,
  iwIconZobaczZakres,
} from '../../assets/css/ewid-svg/svg-icons';
import { RequestType } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { SourceType } from '../../gk-map/models';
import {
  FileUploadOwner,
  GkKendoSuccessEvent,
  GkKendoUploadEvent,
  UploadDocTypeName,
} from '../gk-kendo-upload';
import { GkKendoGridComponent } from './gk-kendo-grid.component';

export type GkKendoGridItem<T, U> = U extends void ? T : U;
export type GkKendoGridItemKey<T, U> = keyof GkKendoGridItem<T, U>;

export interface GkKendoGridDataResult<T, U> {
  data: GkKendoGridItem<T, U>[];
  total: number;
}

export interface GkKendoGridSelection<T, U> {
  selectedKeys: GkKendoGridItemKey<T, U>[];
  selectedRows: GkRowArgs<GkKendoGridItem<T, U>>[] | undefined;
  selectedItems: GkKendoGridItem<T, U>[];
}

export interface GkKendoGridFormControlValue<T, U> {
  data: GkKendoGridItem<T, U>[];
  selection: GkKendoGridItem<T, U>[];
}

export interface GkRowArgs<T> {
  dataItem: T;
  index: number;
}

export interface ColumnSetting {
  field: string;
  title: string;
  type: ColumnType;
  width?: number;
  media?: string;
  format?: string;
  cellTemplate?: (dataItem: unknown) => string;
}

export enum ColumnType {
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Date = 'date',
}

export enum GridToolBarItemType {
  Dropdown = 'dropdown',
  Text = 'text',
  ButtonWithInput = 'buttonWithInput',
}

export interface InputToolBarItemData {
  width?: string;
  min: number;
  max?: number;
  value: number;
  suffixValue?: string;
}

export type GridToolBarItemPayload =
  | GridToolbarItemMapDrawingPayload
  | GenericGridToolbarItemAddFilePayload
  | GenericGridToolbarItemRemoveFilePayload
  | GenericGridToolbarItemDownloadFilePayload
  | GenericGridToolbarItemPreviewFilePayload
  | GenericGridToolbarItemShowGeometryPayload;

export interface GridToolBarItem {
  name: string;
  iconClass?: string | (() => string);
  svgIcon?: SVGIcon | (() => SVGIcon);
  className?: string | (() => string);
  title?: string | (() => string);
  text?: string | (() => string);
  showText?: DisplayMode;
  type?: GridToolBarItemType;
  disabled?: boolean | (() => boolean);
  visible?: boolean | (() => boolean);
  data?: GridToolBarItem[];
  callback?: (payload?: Event | number | GridToolBarItemPayload) => void;
  payload?: () => GridToolBarItemPayload;
  separator?: boolean;
  spacer?: boolean;
  inputData?: InputToolBarItemData;
  beforeClickValidation?: () => boolean;
  buttonThemeColor?: ButtonThemeColor;
  toggleable?: boolean;
  selected?: boolean | (() => boolean);
}

export interface LegendConfig {
  text: string;
  class?: string;
  isVisible?: () => boolean;
}

export interface AbortRequestConfig {
  isAbortRequest: boolean;
  callback?: () => void;
}

export const MIN_WIDTH_FOR_DATE_COLUMN = 90;
export const DEFAULT_MOBILE_COLUMN_WIDTH = 80;

export interface GridToolbarItemMapDrawingPayload {
  doneCallback: (mapObject: any) => void;
}

export interface GenericGridToolbarItemAddFilePayload {
  uploadSaveUrl: string;
  uploadDocTypeDict: UploadDocTypeName;
  uploadData?: {
    Owner: FileUploadOwner;
    OwnerId: number | string;
    FaktUuid?: string;
  };
  onUploadButtonClick?: (
    uploadEvent: EventEmitter<GkKendoUploadEvent>,
  ) => Promise<number>;
  onUploadSuccess?: (uploadEvent: GkKendoSuccessEvent) => void;
}

export interface GenericGridToolbarItemRemoveFilePayload {
  removeUrl: string;
}

export interface GenericGridToolbarItemDownloadFilePayload {
  downloadUrl: string;
  kergId?: number;
  successCallback?: () => void;
}

export interface GenericGridToolbarItemPreviewFilePayload {
  url: string;
  kergId: number;
  requestType?: RequestType;
}

export interface GenericGridToolbarItemShowGeometryPayload {
  url?: string;
  gridGeometryFieldName?: string;
  ajaxPayload?: any;
}

export interface GenericGridToolbarItemGetPolygonGeometryFromMapPayload {
  gridGeometryFieldName?: string;
}

export enum GenericGridToolbarItemName {
  AddFile = 'addFile',
  RemoveFile = 'removeFile',
  RemoveGridItem = 'removeGridItem',
  RemoveAllGridItems = 'removeAllGridItems',
  DownloadFile = 'downloadFile',
  NamePatternFiles = 'namePatternFiles',
  PreviewDocument = 'previewDocument',
  ShowGeometry = 'showGeometry',
  DrawGeometry = 'drawGeometry',
  ModifyGeometry = 'modifyGeometry',
  GetPolygonGeometryFromMap = 'getGeometryFromMap',
  GetPointGeometryFromMap = 'getPointGeometryFromMap',
  Details = 'details',
  Refresh = 'refresh',
  RangeFromSelectedParcels = 'rangeFromSelectedParcels',
  IncreaseRangeByBuffer = 'increaseRangeByBuffer',
  GetLandParcelByPointFromMap = 'getLandParcelByPointingFromMap',
  GetLandParcelByPolygonFromMap = 'getLandParcelByPolygonFromMap',
  SaveRangeToWkt = 'saveRangeToWkt',
  SaveRangeToTxt = 'saveRangeToTxt',
}

export const getGenericGridToolbarItems = (
  c: GkKendoGridComponent<never>,
): GridToolBarItem[] => [
  {
    name: GenericGridToolbarItemName.AddFile,
    svgIcon: iwIconPlus,
    className: 'svg-icon-red',
    title: 'Dodaj',
    callback: (payload) =>
      c.addFile(payload as GenericGridToolbarItemAddFilePayload),
  },
  {
    name: GenericGridToolbarItemName.RemoveFile,
    svgIcon: iwIconMinus,
    className: 'svg-icon-red',
    title: 'Usuń',
    callback: (payload) =>
      c.removeFile(payload as GenericGridToolbarItemRemoveFilePayload),
  },
  {
    name: GenericGridToolbarItemName.RemoveGridItem,
    svgIcon: iwIconMinus,
    className: 'svg-icon-red',
    title: 'Usuń',
    callback: () => c.removeSelectedGridItem(),
  },
  {
    name: GenericGridToolbarItemName.RemoveAllGridItems,
    svgIcon: iwIconCross,
    className: 'svg-icon-red',
    title: 'Usuń wszystko',
    text: 'Usuń wszystko',
    showText: 'overflow',
    callback: () => c.removeAllGridItems(),
  },
  {
    name: GenericGridToolbarItemName.DownloadFile,
    iconClass: 'fa fa-download',
    title: 'REPORTS_IN_SCOPE.DOCS.DOWNLOAD_ALL',
    callback: (payload) =>
      c.downloadFile(payload as GenericGridToolbarItemDownloadFilePayload),
  },
  {
    name: GenericGridToolbarItemName.NamePatternFiles,
    svgIcon: iwIconInfo,
    title: 'WORK_DIGITAL_DOCS.GRID_TOOLBAR.NAME_PATTERN_FILES',
    callback: () => c.showNamePatternFilesWindow(),
  },
  {
    name: GenericGridToolbarItemName.PreviewDocument,
    iconClass: 'fa fa-file-text-o',
    title: 'REPORTS_IN_SCOPE.DOCS.PREVIEW_DOCUMENT',
    callback: (payload) =>
      c.showDocumentPreviewWindow(
        payload as GenericGridToolbarItemPreviewFilePayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.ShowGeometry,
    svgIcon: iwIconZobaczZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    showText: 'overflow',
    callback: (payload) =>
      c.onShowGeometryToolbarButtonClick(
        payload as GenericGridToolbarItemShowGeometryPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.DrawGeometry,
    svgIcon: iwIconWrysujZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    showText: 'overflow',
    callback: (payload) =>
      c.onDrawGeometryToolbarButtonClick(
        payload as GenericGridToolbarItemShowGeometryPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.GetPolygonGeometryFromMap,
    svgIcon: iwIconColorNarysujZakres2,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_RANGE_ON_MAP',
    callback: (payload) =>
      c.onGetPolygonGeometryFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.GetLandParcelByPointFromMap,
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    callback: (payload) =>
      c.onGetLandParcelFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Point,
      ),
  },
  {
    name: GenericGridToolbarItemName.GetLandParcelByPolygonFromMap,
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    callback: (payload) =>
      c.onGetLandParcelFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Polygon,
      ),
  },
  {
    name: GenericGridToolbarItemName.Details,
    svgIcon: iwIconColorSz,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DETAILS',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DETAILS',
    showText: 'overflow',
    callback: () => undefined,
  },
  {
    name: GenericGridToolbarItemName.Refresh,
    iconClass: 'fa fa-refresh',
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.REFRESH',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.REFRESH',
    showText: 'overflow',
    callback: () => c.rebind(),
  },
];

export interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export type ExtendedGkKendoGridComponent<T extends GkKendoGridComponent<any>> =
  T;
