<div class="container mb-4">
  @if (waitingForData) {
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  }

  @if (formCreated) {
    <form
      [hidden]="waitingForData"
      [formGroup]="newGrantsForm"
      (ngSubmit)="handleSubmit()"
      >
      <div class="grant_place my-3">
        <div class="place">
          <label class="sr-only" for="currentPlace">
            {{ 'NEW_GRANT.PLACE' | translate }}
          </label>
          <input
            id="currentPlace"
            class="form-control form-control-sm input-required place-input"
            aria-required="true"
            type="text"
            formControlName="currentPlace"
            [placeholder]="'NEW_GRANT.PLACE' | translate"
            [ngClass]="{ 'input-invalid': submitted && !isPlaceValid() }"
            />
        </div>
      </div>
      @if (submitted && !isPlaceValid()) {
        <div class="alert alert-danger">
          {{ 'NEW_GRANT.FILL_REQUIRED_FIELDS' | translate }}
        </div>
      }
      <h2 class="grant__main-header mb-3">
        <span class="text-uppercase">{{ 'NEW_GRANT.HEADER' | translate }}</span>
        <span>{{ 'NEW_GRANT.REQUEST_PURPOSE' | translate }}</span>
      </h2>
      <span class="my-2 fw-bold">
        {{ 'NEW_GRANT.USER_OF_E_SERVICES' | translate }}
      </span>
      <section class="my-3 petent-section">
        <div class="petent-field">
          <label for="pzLogin" class="petent-label">
            <span class="text-uppercase">{{
              'NEW_GRANT.USER_LOGIN' | translate
            }}</span>
            &nbsp;{{ 'NEW_GRANT.LOGIN_FOR' | translate }}
          </label>
          <input
            id="pzLogin"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="pzLogin"
            />
        </div>
        <div class="petent-field">
          <label for="firstname" class="petent-label">
            {{ 'NEW_GRANT.FIRSTNAME' | translate }}
          </label>
          <input
            id="firstname"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="firstname"
            />
        </div>
        <div class="petent-field">
          <label for="lastname" class="petent-label">
            {{ 'NEW_GRANT.LASTNAME' | translate }}
          </label>
          <input
            id="lastname"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="lastname"
            />
        </div>
        <div class="petent-field">
          <label for="address" class="petent-label">
            {{ 'NEW_GRANT.ADDRESS' | translate }}
          </label>
          <input
            id="address"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="address"
            />
        </div>
        <div class="petent-field">
          <label for="postalCode" class="petent-label">
            {{ 'NEW_GRANT.POSTAL_CODE' | translate }}
          </label>
          <input
            id="postalCode"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="postalCode"
            />
        </div>
        <div class="petent-field">
          <label for="place" class="petent-label">
            {{ 'NEW_GRANT.PLACE' | translate }}
          </label>
          <input
            id="place"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="place"
            />
        </div>
        <div class="petent-field">
          <label for="phone" class="petent-label">
            {{ 'NEW_GRANT.PHONE' | translate }}
          </label>
          <input
            id="phone"
            class="form-control form-control-sm petent-input"
            type="text"
            formControlName="phone"
            />
        </div>
        <div class="petent-field">
          <label for="companyName" class="petent-label">
            {{ 'NEW_GRANT.COMPANY_NAME' | translate }}
          </label>
          <input
            id="companyName"
            class="form-control form-control-sm petent-input input-required"
            aria-required="true"
            type="text"
            formControlName="companyName"
            [placeholder]="'NEW_GRANT.COMPANY_NAME_PLACEHOLDER' | translate"
          [ngClass]="{
            'input-invalid':
              submitted && newGrantsForm.get('companyName').invalid
          }"
            />
        </div>
        @if (submitted && newGrantsForm.get('companyName').invalid) {
          <div
            class="alert alert-danger"
            >
            {{ 'NEW_GRANT.FIELD_REQUIRED' | translate }}
          </div>
        }
      </section>
      <section class="my-3 rights-section">
        <span class="my-1 fw-bold">{{ 'NEW_GRANT.ASKING_FOR' | translate }}</span>
        @for (right of getRights(); track right; let i = $index) {
          <div
            class="right-field-wrapper"
            [formGroupName]="right"
            >
            <div class="right-field">
              <input
                #rightCheckbox
                type="checkbox"
                class="mb-1 me-2"
                [attr.aria-labelledby]="right + 'number-label'"
                formControlName="checked"
                />
              <label
                [id]="right + 'number-label'"
                class="right-label mb-0 me-2"
                [attr.for]="right + 'number'"
                >
                {{ 'NEW_GRANT.' + right.toUpperCase() | translate }}
                @if (
                  rightCheckbox.checked &&
                  rightToGrantPermissionNumberRequired[right]
                  ) {
                  {{ 'NEW_GRANT.GRANT_NUMBER' | translate }}
                }
              </label>
              @if (
                rightCheckbox.checked &&
                rightToGrantPermissionNumberRequired[right]
                ) {
                <input
                  class="form-control form-control-sm right-input"
                  [id]="right + 'number'"
                  type="text"
                  formControlName="number"
                  />
              }
            </div>
            @if (getRightNumber(right) && rightCheckbox.checked) {
              <div
                class="attachment-field"
                >
                <label class="attachment-label" [attr.for]="right + 'attachment'">
                  {{ 'NEW_GRANT.APPROVING_DOCUMENT' | translate }}
                </label>
                <app-attachment-input
                  class="w-100"
                  [isRequired]="!!getRightNumber(right)"
                  [wasValidated]="submitted"
                  [fileInputId]="$any(right)"
                  (fileChanged)="handleAttachmentChangeEvent($event)"
                ></app-attachment-input>
              </div>
            }
            @if (submitted && !isRightCorrectlyFilledOrEmpty(right)) {
              <div
                class="alert alert-danger"
                >
                {{ 'NEW_GRANT.FILL_REQUIRED_FIELDS' | translate }}
              </div>
            }
          </div>
        }
        @if (submitted && !isAnyRightChosen()) {
          <div class="alert alert-danger">
            {{ 'NEW_GRANT.CHOOSE_POSITION' | translate }}
          </div>
        }
        @if (submitted && !isEveryRightValid()) {
          <div class="alert alert-danger">
            {{ 'NEW_GRANT.FILL_REQUIRED_FIELDS' | translate }}
          </div>
        }
      </section>
      <section class="my-2">
        <app-cautions [portalId]="portalId"></app-cautions>
      </section>
      @if (submitted && !isEverythingValid()) {
        <div class="alert alert-danger">
          {{ 'NEW_GRANT.FILL_REQUIRED_FIELDS' | translate }}
        </div>
      }
      <section>
        <button type="submit" class="btn btn-primary">
          {{ 'NEW_GRANT.SUBMIT' | translate }}
        </button>
        <button
          type="button"
          [routerLink]="['/office-departments-portal/departments']"
          class="btn btn-outline-primary ms-2"
          >
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.CANCEL' | translate }}
        </button>
        <div class="d-flex flex-column">
          @if (docSignMsgTxt) {
            <span [ngClass]="'text-' + docSignMsgType">
              {{ docSignMsgTxt }}
            </span>
          }
          @if (docSignUrl) {
            <span class="my-2 text-info">
              {{ docSignTranslations['SIGN_INFO'] }}
            </span>
          }
          @if (docSignUrl) {
            <a class="link" [href]="docSignUrl" target="_blank">
              {{ docSignTranslations['SIGN'] }}
            </a>
          }
          @if (docSignPending) {
            <div class="d-flex justify-content-center my-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            </div>
          }
        </div>
        <div class="mt-2"><app-form-legend></app-form-legend></div>
      </section>
    </form>
  }

  @if (error) {
    <div class="alert alert-danger mt-2" [hidden]="waitingForData">
      {{ 'ERROR_OCCURED' | translate }}
    </div>
  }
</div>
