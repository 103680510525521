@if (cautions?.isNotEmpty()) {
  @if (cautions.mainCaution) {
    <section>
      <div class="card mb-4" [ngClass]="borderClass">
        <div class="card-header text-white" [ngClass]="backgroundClass">
          {{ 'CAUTIONS.MAIN_CAUTION' | translate }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 static-text-wrapper-max-height">
              <p class="mb-0" [innerHTML]="cautions.mainCaution"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
  @if (cautions.procedure) {
    <section>
      <div class="card mb-4" [ngClass]="borderClass">
        <div class="card-header text-white" [ngClass]="backgroundClass">
          {{ 'CAUTIONS.PROCEDURE' | translate }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 static-text-wrapper-max-height">
              <p class="mb-0" [innerHTML]="cautions.procedure"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
  @if (cautions.rodoClause) {
    <section>
      <div class="card mb-4" [ngClass]="borderClass">
        <div class="card-header text-white" [ngClass]="backgroundClass">
          {{ 'CAUTIONS.RODO_CLAUSE' | translate }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 static-text-wrapper-max-height">
              <p class="mb-0" [innerHTML]="cautions.rodoClause"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
  <div class="alert alert-info" role="alert">
    {{ 'CAUTIONS.ACCEPTANCE_INFORMATION_DESCRIPTION_LABEL' | translate }}
    {{ this.cautionsService.getCautionInfoText(portalId) | async }}.
  </div>
}
