import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { PresentationCommentsLandBuildingRegistryProjectConfigService } from '../../modules/presentation-comments-land-building-registry-project/presentation-comments-land-building-registry-project-config.service';
import { PortalConfig } from './portal-config.model';
import { SurveyorPortalProjectConfigService } from '../../modules/surveyor-portal/surveyor-portal-project-config.service';
import { AppraiserPortalProjectConfigService } from '../../modules/appraiser-portal/appraiser-portal-project-config.service';

@Injectable({
  providedIn: 'root',
})
export class PortalConfigService {
  constructor(
    private presentationCommentsLandBuildingRegistryProjectConfigService: PresentationCommentsLandBuildingRegistryProjectConfigService,
    private surveyorPortalProjectConfigService: SurveyorPortalProjectConfigService,
    private appraiserPortalProjectConfigService: AppraiserPortalProjectConfigService,
  ) {}
  getPortalConfByMainRoute(mainRoute: MainRoutes): Observable<PortalConfig> {
    switch (mainRoute) {
      case MainRoutes.PresentationCommentsLandBuildingRegistryProject:
        return this.presentationCommentsLandBuildingRegistryProjectConfigService.getPortalConfig();
      case MainRoutes.SurveyorPortal:
        return this.surveyorPortalProjectConfigService.getPortalConfig();
      case MainRoutes.AppraiserPortal:
        return this.appraiserPortalProjectConfigService.getPortalConfig();
      case MainRoutes.Designer:
      case MainRoutes.Przp:
      case MainRoutes.GrantingRights:
      case MainRoutes.NetworkServiceOrder:
      case MainRoutes.RoadExitPortal:
      case MainRoutes.LaneOccupancyPortalInfrastructureDevices:
      case MainRoutes.LaneOccupancyPortalBuildingObjects:
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse:
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse:
      case MainRoutes.BuildingPermitTransfer:
      case MainRoutes.LaneOccupancyPortalBuildingWorks:
      case MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest:
      case MainRoutes.EnvironmentalProtectionPortalFishingCardRequest:
      case MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest:
      case MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest:
      case MainRoutes.ConstructionPortalConstructionNotificationRequest:
      case MainRoutes.ConstructionPortalDemolitionNotificationRequest:
      case MainRoutes.ConstructionPortalConstructionLogRequest:
      case MainRoutes.ConstructionPortalPremiseIndependenceRequest:
      case MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan:
      case MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan:
      case MainRoutes.PlanningDepartmentExtractFromStudy:
      case MainRoutes.AddressNumberRequestPortal:
      case MainRoutes.OwnershipConversion:
      case MainRoutes.SupplementaryParcelPurchase:
      case MainRoutes.PropertyLease:
      case MainRoutes.StreetNaming:
      case MainRoutes.CommunalPremisePurchase:
      case MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest:
      case MainRoutes.SoilClassificationOfLandRequest:
      case MainRoutes.AccessToInformationOnEnvironmentAndItsProtection:
      case MainRoutes.BuildingPermit:
      case MainRoutes.DemolitionPermit:
      case MainRoutes.BuildingOrPartUseChange:
      case MainRoutes.TemporaryStructureBuildingPermit:
      case MainRoutes.BuildingPermitModificationDecision:
      case MainRoutes.NotObjectedNotificationRightsObligationsTransfer:
      case MainRoutes.PlotLandDevArchConstrProjSeparateDecision:
      case MainRoutes.AdjacentPropertyNeedEnterDecision:
      case MainRoutes.SingleFamilyBuilding70ConstructionConversion:
      case MainRoutes.SingleFamilyBuildingConstructionConversion:
      default:
        return of(null);
    }
  }
}
