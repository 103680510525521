import { Extent } from 'ol/extent';
import WKT from 'ol/format/WKT';
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import { DrawEvent } from 'ol/interaction/Draw';
import { transformExtent } from 'ol/proj';
import { GeometryFormat, MapLineString, MapPoint, MapPolygon, OpenLayersGeometryType, ProjectionCode, Wkt, WktType } from '../../models';

export class ConversionUtils {
  static getTransformedExtent(
    mapExtent: Extent,
    projectionFrom: ProjectionCode,
    projectionTo: ProjectionCode
  ): Extent {
    return transformExtent(mapExtent, projectionFrom, projectionTo);
  }

  static getGeometryFromDrawing(
    drawing: DrawEvent,
    projectionFrom: ProjectionCode,
    projectionTo: ProjectionCode
  ): Geometry {
    return drawing.feature
      .getGeometry()
      .transform(projectionFrom, projectionTo);
  }

  static getGeometryFormat(geom: Wkt): string {
    try {
      new WKT().readGeometry(geom);
      return GeometryFormat.Wkt;
    } catch (err) {
      return GeometryFormat.Txt;
    }
  }

  static getGeometryFromWkt(wkt: Wkt): Geometry {
    return new WKT().readGeometry(wkt);
  }

  static getWktFromGeometry(geometry: Geometry): Wkt {
    return new WKT().writeGeometry(geometry);
  }

  static getWktType(wktGeometry: Wkt): WktType {
    return ConversionUtils.getAlphabetCharacters<WktType>(wktGeometry);
  }

  static getAlphabetCharacters<T extends string>(string: string): T {
    return string.replace(/[^A-Za-z]/g, '') as T;
  }

  static getMapGeometryCollectionBasedOnWktType(
    wktGeometry: Wkt,
    wktType: WktType
  ): MapPolygon[] | MapPoint[] | MapLineString[] | undefined {
    switch (wktType) {
      case WktType.Point:
        return [
          (this.getGeometryFromWkt(wktGeometry) as Point).getCoordinates(),
        ];
      case WktType.LineString:
        return [
          (this.getGeometryFromWkt(wktGeometry) as LineString).getCoordinates(),
        ];
      case WktType.Polygon:
        return [
          (this.getGeometryFromWkt(wktGeometry) as Polygon).getCoordinates()[0],
        ];
      case WktType.MultiPoint:
        return (
          this.getGeometryFromWkt(wktGeometry) as MultiPoint
        ).getCoordinates();
      case WktType.MultiLineString:
        return (
          this.getGeometryFromWkt(wktGeometry) as MultiLineString
        ).getCoordinates();
      case WktType.MultiPolygon:
        return (this.getGeometryFromWkt(wktGeometry) as MultiPolygon)
          .getCoordinates()
          .map((polygon) => polygon[0]);
      default:
        return undefined;
        break;
    }
  }

  static getReducedGeometry(geometry: Geometry): Geometry {
    switch (geometry.getType()) {
      case OpenLayersGeometryType.Polygon:
        return new Polygon([(geometry as Polygon).getCoordinates()[0]]);
      case OpenLayersGeometryType.MultiPolygon:
        return new MultiPolygon([
          (geometry as MultiPolygon)
            .getCoordinates()
            .map((polygon) => polygon[0]),
        ]);
      default:
        return geometry;
    }
  }
}
