<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">{{ 'STREET_NAMING.TITLE' | translate }}</h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'STREET_NAMING.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectCategoryOrDescriptionFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ObjectCategoryOrDescription"
          >
            {{ 'STREET_NAMING.OBJECT_CATEGORY_OR_DESCRIPTION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectCategoryOrDescription"
                [id]="controlName.ObjectCategoryOrDescription"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectCategoryOrDescription"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectCategoryOrDescriptionFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectProposedNewNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ObjectProposedNewName">
            {{ 'STREET_NAMING.OBJECT_PROPOSED_NEW_NAME' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectProposedNewName"
                [id]="controlName.ObjectProposedNewName"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectProposedNewName"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectProposedNewNameFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectLocationFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ObjectLocation">
            {{ 'STREET_NAMING.OBJECT_LOCATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectLocation"
                [id]="controlName.ObjectLocation"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectLocation"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectLocationFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card mb-4">
        <div class="card-header text-white bg-dark">
          {{ 'STREET_NAMING.OBJECT_DESIGNATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectNamingJustificationFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ObjectNamingJustification"
          >
            {{ 'STREET_NAMING.OBJECT_NAMING_JUSTIFICATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectNamingJustification"
                [id]="controlName.ObjectNamingJustification"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectNamingJustification"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectNamingJustificationFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{ 'STREET_NAMING.DECISION_FORM' | translate }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="controlName.DecisionForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label class="form-check-label" [attr.for]="controlName.DecisionForm">
            {{ 'STREET_NAMING.NON_ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'STREET_NAMING.SHIPPING_FORM' | translate }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [removeDisabledControls]="true"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'STREET_NAMING.SHIPPING_FORM_REQUIRED' | translate }}
            </div>
          </div>
        }
      </section>
    }

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{ 'STREET_NAMING.ADDITIONAL_INFORMATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-2">
      <app-cautions [portalId]="portalId"></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>{{ 'STREET_NAMING.PROVIDE_REQUIRED_FIELDS' | translate }}</span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'STREET_NAMING.SUBMIT' | translate }}
      </button>

      <button
        type="button"
        [routerLink]="['/office-departments-portal/departments']"
        class="btn btn-outline-primary"
      >
        {{ 'STREET_NAMING.CANCEL' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
